<template>
  <page-view :title="pageTitle" left-arrow :nav-border="false" custom-class="page-sbjlcx">
    <template #nav-right>
      <span style="color: white;padding: 0 10px;" @click="selectShow = !selectShow">筛选</span>
    </template>
    <van-form
        v-show="selectShow"
        :show-error="true"
        :show-error-message="false"
        :scroll-to-error="true"
        :validate-first="true"
        @submit="getGfSqRecord">
      <van-field
          name="isNil"
          label="非系统户"
          input-align="right">
        <template #input>
          <van-switch v-model="form.isNil" size="20" active-value="1" inactive-value="0" @change="handleIsNilChange"/>
        </template>
      </van-field>
      <van-field
          v-model="form.yhbh"
          name="yhbh"
          label="用户编号"
          placeholder="用户编号"
          :disabled="form.isNil == '1' ? true: false"
      />
      <van-field
          v-if="form.isNil == '1'"
          name="lxsj"
          v-model="form.lxsj"
          label="联系电话"
          placeholder="联系电话"
          :rules="[{ required: form.isNil == '1' ? true: false, message: '请填写联系电话' }]"
      />

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">查询</van-button>
      </div>
    </van-form>
    <loading-layout ref="loadingLayout" :error-text="pageErrortext">
      <template #empty>
        <van-empty :description="pageErrortext" :image="require('@/assets/images/load-error.png')">
          <van-button type="primary" class="bottom-button" @click="pageBack" size="small" style="padding: 4px 20px;">返回</van-button>
        </van-empty>
      </template>
      <van-list class="gfbw-list" v-model="listLoading" :finished="listFinished" finished-text="没有更多了" :error.sync="listLoadError" error-text="加载失败，点击重试"
                @load="getGfSqRecord">
        <div :class="'gfbw-item'" v-for="(child,idx) in gfbwjlList" :key="idx">
          <div class="gfbw-item--header">
            <van-tag type="primary" class="zwyf mt-15"></van-tag>
          </div>
          <van-cell-group class="gfbw-item--content">
            <van-cell :border="false" title="业务编号：">{{child.ywbh}}</van-cell>
            <van-cell :border="false" title="用户编号：">{{child.yhbh}}</van-cell>
            <van-cell :border="false" title="机构名称：" v-if="child.jgbm && child.zzmc">{{child.jgbm+'-'+child.zzmc}}</van-cell>
            <van-cell :border="false" title="机构名称：" v-else-if="child.jgbm && !child.zzmc">{{child.jgbm}}</van-cell>
            <van-cell :border="false" title="机构名称：" v-else>无</van-cell>
            <van-cell :border="false" title="项目名称：">{{child.xmmc}}</van-cell>
            <van-cell :border="false" title="项目建设方：">{{child.xmjsf}}</van-cell>
            <van-cell :border="false" title="联系电话：">{{child.lxsj}}</van-cell>
            <van-cell :border="false" title="当前状态：">
              <van-tag type="success" v-if="child.dqzt==3">{{statusMc(child.dqzt)}}</van-tag>
              <van-tag type="danger" v-else-if="child.dqzt==4">{{statusMc(child.dqzt)}}</van-tag>
              <van-tag type="primary" v-else>{{statusMc(child.dqzt)}}</van-tag>
            </van-cell>
            <van-cell :border="false" title="申请日期：" v-if="child.qdrq">{{child.qdrq}}</van-cell>
            <van-cell :border="false" title="受理日期：" v-if="child.slTime">{{child.slTime}}</van-cell>
            <van-cell :border="false" title="审核日期：" v-if="child.shrq">{{child.shrq}}</van-cell>
            <van-cell :border="false" title="审核结果：" v-if="child.shjg == 1 || child.shjg == 2">
              <van-tag :type="child.shjg == 1?'success':'danger'">{{child.shjg == 1?'审核通过':'审核未通过'}}</van-tag>
            </van-cell>
            <van-cell :border="false" title="审核原因：" v-if="child.shyy">{{child.shyy}}</van-cell>
          </van-cell-group>
          <div class="gfbw-item--actions">
            <van-button type="info" size="small" @click="handleViewGfbw(child)">查看</van-button>
            <van-button type="primary" size="small" @click="handleEditGfbw(child)" v-if="child.dqzt == 3 && child.shjg == 2">重新申请</van-button>
          </div>
        </div>
      </van-list>
    </loading-layout>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';
import VantSelect from '@/components/VantSelect/index.vue'

import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
import YhbhSelect from '@/components/YhbhSelect/index.vue';
import {Tab,Tabs} from 'vant';
import {ykGfywxx_selectiveGet,ykGfywxx_getYkGfywxxForMobile,ykGfywxx_getYkGfywxxlist} from "@/api/psdmsschjy/schjyschema/YkGfywxxAPI";

export default {
    name: 'gfbwjlcx',
    components: {
      VantSelect,
      YhbhEmpty,
      YhbhSelect,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
    },
    data() {
      return {
        pageTitle: "并网记录查询",
        pageErrortext: '',
        yhbh: null,
        yhJbxx: {},
        gfbwjlList: [],
        sbjhxx:{},
        sbjhmx: {},
        form: {
          yhbh: null,
          yhmc: '',
          yhbz: '9',
          jhyf: null,
          jbflb: '',
          jhdl: 0,
          isNil: '0',
          lxsj:'',
        },
        files: [],
        statusList:[
          {
            value:'待受理',
            key:1,
          },
          {
            value:'待审核',
            key:2,
          },
          {
            value:'已审核',
            key:3,
          },
          {
            value:'作废',
            key:4,
          }
        ],
        activeTabIndex: 0,
        listLoading: false,
        listFinished: false,
        listLoadError: false,
        selectShow:false,
      }
    },
    computed: {
        ...mapGetters(['jgbm','dybm','czyh','dlzh','yhid','yhsj','yhbhList']),
      funcList() {
        let list = [];
        switch(this.jgbm) {
          case "1R6":
            list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dfcj','yfkcr'];
            break;
          case "11":
            list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'ksjf', 'gdcx', 'dzhtgl', 'dfcj','yfkcr'];
            break;
          default:
            list = ['dfzd', 'dzfp','jfjl','ywbl','gzbx','schjy', 'bankPayment', 'gdcx', 'dzhtgl'];
            break;
        }
        return list;
      }
    },
    methods: {
      statusMc(val){
        let mc = '';
        this.statusList.forEach((item,index)=>{
          if(item.key==val){
            mc = item.value;
          }
        })
        return mc;
      },
      pageBack() {
        this.$router.go(-1)
      },
      pageSetup() {
        this.$refs['loadingLayout'].setStatus('loading');
        this.$refs['loadingLayout'].setStatus('success');
      },
      isShowFunc(funcName) {
        return this.funcList.indexOf(funcName) != -1;
      },
      async getGfSqRecord(){
        this.listFinished = false;
        this.listLoadError = false;
        this.gfbwjlList = [];
        this.listLoading = true;
        let res = [];
        if(this.form.isNil == '1'){
          res = await ykGfywxx_getYkGfywxxlist(
            this.jgbm,'','',this.form.yhbh,
            [],'','',
            this.form.lxsj,'',
            {}
          );
          if(res.code == 200){
            this.gfbwjlList = res.content;
          } else {
            this.listLoadError = true;
          }
        }else{
          let yhbhList = this.yhbhList.map((item,index)=>{
            return item.yhbh;
          })
          if(this.form.yhbh){
            yhbhList = [this.form.yhbh];
          }
          if(yhbhList.length){
            res = await ykGfywxx_getYkGfywxxForMobile(yhbhList,{
              orderBy:'qdrq desc'
            });
            if(res.code == 200){
              this.gfbwjlList = res.content;
            } else {
              this.listLoadError = true;
            }
          }
        }
        this.listFinished = true;
        this.listLoading = false;
        this.selectShow = false;
      },
      handleViewGfbw(item){
        this.$router.push({
          path: '/gfbwsq',
          query:{
            ywbh: item.ywbh,
            //类型1为申请,2为查看,3为审核失败再次修改提交
            type: 2
          }
        })
      },
      handleEditGfbw(item){
        this.$router.push({
          path: '/gfbwsq',
          query:{
            ywbh: item.ywbh,
            //类型1为申请,2为查看,3为审核失败再次修改提交
            type: 3
          }
        })
      },
      handleIsNilChange(val) {
        if (val == '1') {
          this.form.yhbh = '非系统用户';
        } else {
          this.form.yhbh = '';
          this.form.lxsj = '';
        }
      },
    },
    mounted() {
      this.$refs['loadingLayout'].setStatus('loading');
      this.getGfSqRecord();
      this.$refs['loadingLayout'].setStatus('success');
      // this.$store.dispatch('getYhbhList').then(list => {
      //     if (list.length == 0) {
      //
      //     } else {
      //         this.yhbh = list[0].yhbh;
      //         this.yhJbxx = list[0]
      //         this.pageSetup();
      //     }
      // }).catch(err => {
      //
      // });
    },
}
</script>

<style lang="scss" scoped>

  $top-bg-color: #00b1ff;

  .page-sbjlcx /deep/ {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .van-nav-bar {
      background-color:  $top-bg-color;
    }
    .van-nav-bar .van-icon {
      color: #fff;
    }
    .van-nav-bar__title {
      color: #fff;
    }
    .van-nav-bar__right {
      padding: 0;
    }

    .top {
      flex-basis: 152px;
      background-color: $top-bg-color;
      position: relative;
    }
    .top::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url(/static/img/city-shadow.11e155ae.png);
      background-position: bottom;
      background-size: auto 90%;
      background-repeat: no-repeat;
      opacity: 0.13;
    }

    .yh-zhxx {
      position: relative;
      z-index: 1;
      padding: 20px 40px;
      color: #fff;
      font-size: 14px;
      background: rgba(0,177, 255,0.68);
      dl {
        margin: 0;
        line-height: 26px;
      }
      dt, dd {
        display: inline-block;
      }
      dd {
        margin-left: 0;
      }
    }
  }
  .gfbw-item{
    margin: 0 15px;
  }
  .gfbw-item--actions {
    padding: 10px 15px;
    text-align: right;
    background-color: white;

    .van-button {
      margin-right: 10px;
      padding: 0 16px;
      border-radius: 3px;
      height: 28px;
      &:last-child {
        margin-right: 0;
      }
    }

    .divider {
      width: 1px;
      height: 16px;
      background-color: #ddd;
    }
  }
  .gfbw-item--content{
    .van-cell{
      padding: 5px 10px;
    }
  }
</style>